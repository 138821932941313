'use strict'

angular.module('cb.maintenance-service', [])
    .factory('MaintenanceService', ["ApiService", function (ApiService) {

        var maintenanceService = {
            getDistrict: function (data) {
                try {
                    var url = '/webapi/api/v1/maintenance/getDistricts';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            updateDistrict: function (data) {
                try {
                    var url = '/webapi/api/v1/maintenance/updateDistrict' ;
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			getGroup: function (id) {
				try {
					var url = '/webapi/api/v1/maintenance/getGroup/'+id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            createDistrict: function (data) {
                try {
                    var url = '/webapi/api/v1/maintenance/createDistrict';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            deleteDistrict: function (id) {
                try {
                    var url = '/webapi/api/v1/maintenance/deleteDistrict/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getGlobalParams: function (data) {
                try {
                    var url = '/webapi/api/v1/maintenance/getGlobalParams';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            deleteParameter: function (id) {
                try {
                    var data = { ParameterId: id };
                    var url = '/webapi/api/v1/maintenance/deleteParameter';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			updateParameter: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/updateParameter';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			getUserGroups: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/getUserGroups';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			deleteUserGroup: function (id) {
				try {
					var data = { ParameterId: id };
					var url = '/webapi/api/v1/maintenance/deleteUserGroup';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            updateUserGroup: function (data) {
                try {
                    var url = '/webapi/api/v1/maintenance/updateUserGroup';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getAllActions: function () {
            try {
                var url = '/webapi/api/v1/maintenance/getAllActions';
                return ApiService.promiseGet(url);
            }
            catch (e) {
                console.error('Error!', e);
            }
            },

            getAllStatuses: function () {
            try {
                var url = '/webapi/api/v1/maintenance/getAllStatuses';
                return ApiService.promiseGet(url);
            }
            catch (e) {
                console.error('Error!', e);
            }
            },
            getAllStatuseGroups: function () {
                try {
                    var url = '/webapi/api/v1/maintenance/getAllStatuseGroups';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			getSalesAreas: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/getAllSalesArea/';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			getAllRegions: function () {
				try {
					var url = '/webapi/api/v1/maintenance/getAllRegions';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateArea: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/updateArea';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			fetchPartPostcodes: function (Id) {
				try {
					var url = '/webapi/api/v1/maintenance/fetchPartPostcodes/' + Id
					return ApiService.promisePost(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			addPartPostcode: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/addPartPostcode';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			removePartPostcode: function (Id) {
				try {
					var url = '/webapi/api/v1/maintenance/removePartPostcode/' + Id;
					return ApiService.promisePost(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateRegions: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/updateRegions';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateSuppliers: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/updateSupplier';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			createSupplier: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/createSupplier';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			UnlinkCloudDetails: function (data) {
				try {
					var url = '/webapi/api/v1/file/SynoUnlinkAccount';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			getQuoteOptions: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/getQuoteOptions';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			deleteQuoteOption: function (id) {
				try {
					var data = { QuoteOptionId: id };
					var url = '/webapi/api/v1/maintenance/deleteQuoteOption';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			addQuoteOption: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/addQuoteOption';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateQuoteOption: function (data) {
				try {
					var url = '/webapi/api/v1/maintenance/updateQuoteOption';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}
        };

        return maintenanceService;
    }]);
